import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";

const QualitätsmanagementPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <React.Fragment>
      <Helmet>
        <html lang="de" />
        <title>
          Gastroenterologen Dres. Seutter & Flüeck Qualitätsmanagement
        </title>
        <meta
          name="description"
          content="Wir betreiben Qualitätsmanagement nach DIN EN ISO 9001:2015"
        />

        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta
          property="og:title"
          content="Gastroenterologen Dres. Seutter & Flüeck Qualitätsmanagement"
        />
        <meta property="og:url" content="/qualitaetsmanagement" />
        <meta
          property="og:description"
          content="Wir betreiben Qualitätsmanagement nach DIN EN ISO 9001:2015"
        />
        <link rel="canonical" href="https://gastroenterologen-nuernberg.de/qualitaetsmanagement" />
      </Helmet>
      <Layout>
        <div
          style={{
            backgroundImage:
              "linear-gradient(90deg, rgba(0,34,82) 0%, rgba(0,106,255) 100%), radial-gradient(circle, rgba(255,255,255,0.5) 0%, rgba(0,0,0,0.5) 100%)",
            backgroundBlendMode: "multiply",
          }}
        >
          <div class="px-20p sm:px-10">
            <div class="max-w-900p mx-auto text-white py-35p sm:py-60p">
              <h1 class="font-bold text-15152 sm:text-24242">
                {frontmatter.title}
              </h1>
            </div>
          </div>
        </div>
        <div class="py-50p sm:py-80p px-20p sm:px-10">
          <div class="max-w-900p mx-auto space-y-35p sm:space-y-60p">
            <div class="text-custom-grey text-09697 sm:text-10909 space-y-15p sm:space-y-35p">
              <p>{frontmatter.description}</p>
              <h2 class="font-bold">{frontmatter.dinNorms}</h2>
              <p>{frontmatter.description2}</p>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default QualitätsmanagementPage;

export const query = graphql`
  {
    markdownRemark(
      frontmatter: { templateKey: { eq: "qualitaetsmanagement-page" } }
    ) {
      frontmatter {
        title
        description
        description2
        dinNorms
      }
    }
  }
`;
